<template>
  <div>
    <div class="help el-icon-search">
      <a
        href="https://bozl966ikj.194gtlwq.com/chatwindow.aspx?siteId=60003589&planId=4507441c-3e97-455f-b3e9-87e0a8098bf6"
        >帮助
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.help {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 999;
  width: 100px;
  height: 50px;
  line-height: 50px;
  background-color: #00be40;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
}
</style>